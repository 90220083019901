import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Logo from './Logo';
import { lighten } from 'polished';
import mq from '../../styles/media-queries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Container, Row } from '../../styles/common/Layout';

const NavWrapper = styled.div`
  background-color: var(--white);
  width: 100%;
  border-bottom: 1px solid ${lighten(0.55, '#982384')};

  &.is-fixed {
    position: fixed;
    top: 0;
    z-index: 101;
  }
`;

const NavBrand = styled.div`
  align-self: center;
  margin-left: 20px;

  ${mq.sm} {
    margin-left: 0px;
    width: 100%;
    margin-right: 100px;
    width: 300px;
    max-width: inherit;
  }
`;

const NavStyles = styled(Container)`
  transition: top 1s linear;
`;

const NavRow = styled(Row)`
  flex-wrap: wrap;
  justify-content: space-between;

  ${mq.sm} {
    flex-wrap: nowrap;
  }
`;

const NavBar = styled.div`
  display: flex;
  width: 100%;

  ${mq.md} {
    justify-content: space-around;
  }
`;

const MainNav = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  text-align: left;
  list-style: none;
  opacity: 1;
  transition: height 200ms 0ms, opacity 200ms 200ms linear;
  flex-direction: column;

  &.hidden {
    opacity: 0;
    height: 0px;
    transition: height 200ms 200ms, opacity 200ms 0ms;
  }

  ${mq.sm} {
    flex-direction: row;
    justify-content: space-around;
    &.hidden {
      opacity: 1;
      height: auto;
      transition: height 200ms 200ms, opacity 200ms 0ms;
    }
  }

  li {
    position: relative;
    padding: 0;
    margin-bottom: 0;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;

    ${mq.sm} {
      padding: 50px 0;
    }

    &.desktop-hidden {
      ${mq.sm} {
        display: none;
      }
    }

    &:focus-within a {
      outline: none;
    }

    a {
      &.active {
        color: var(--mainColor);
        &:not(.logo):after {
          width: 100%;
        }
      }
    }

    &:hover {
      ${mq.sm} {
        .sub-menu {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
`;

const SubMenu = styled.ul`
  position: absolute;
  left: 0;
  top: 125px;
  visibility: hidden;
  opacity: 0;
  padding: 0;
  transition: opacity 0.2s ease-in;
  width: 240px;
  border-top: 3px solid var(--mainColor);
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.06);
  z-index: 100;

  li {
    text-align: left;
    padding: 0;
    a {
      width: 100%;
      padding: 15px 10px;
      background-color: rgba(255, 255, 255, 0.96);
    }

    &:hover,
    &:focus-within {
      a {
        color: var(--mainColor);
        background: ${lighten(0.55, '#982384')};
        &:after {
          width: 0 !important;
          height: 0;
        }
      }
    }
  }
`;

const LinkStyles = styled(Link)`
  position: relative;
  text-transform: uppercase;
  font-size: 1.7rem;
  text-decoration: none;
  padding: 0em;
  transition: font-size 0.25s linear;
  color: var(--darkgrey);
  -webkit-font-smoothing: subpixel-antialiased;

  &:not(.logo):hover {
    color: var(--mainColor);
  }

  &:before {
    transition-property: color, background-color, border-color;
    transition-duration: 0.2s;
    transition-timing-function: linear;
  }

  &:after {
    transition: all 0.2s;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: var(--mainColor);
    height: 1px;
    top: calc(50% + 1.15em);
  }
  &:not(.logo):hover:after {
    width: 100%;
  }
`;
const ToggleButton = styled.button`
  visibility: visible;
  opacity: 1;
  display: block;
  width: 25%;

  background-color: transparent;
  color: var(--mainColor);
  border: none;
  svg {
    color: var(--mainColor);
  }
  ${mq.sm} {
    visibility: hidden;
    opacity: 0;
    display: none;
  }
`;

export default function Nav({ position }) {
  const [isExpanded, toggleExpansion] = useState(false);

  const handleStateChange = (state) => {
    toggleExpansion(state.isOpen);
  };

  const closeMenu = () => {
    toggleExpansion(false);
  };
  return (
    <NavWrapper className={position.y > 45 ? 'is-fixed' : ''}>
      <NavStyles>
        <NavRow>
          <NavBrand>
            <LinkStyles className="logo" to="/">
              <Logo />
            </LinkStyles>
          </NavBrand>
          <ToggleButton
            data-target="navigation"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <FontAwesomeIcon icon={faBars} size="4x" />
          </ToggleButton>
          <NavBar role="navigation">
            <MainNav className={`${isExpanded ? `block` : `hidden`}`}>
              <li>
                <LinkStyles
                  to="/"
                  activeClassName="active"
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  Startseite
                </LinkStyles>
              </li>
              <li>
                <LinkStyles
                  aria-haspopup="true"
                  partiallyActive={true}
                  activeClassName="active"
                  to="/physiotherapie"
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  Physiotherapie
                </LinkStyles>
                <SubMenu className="sub-menu">
                  <li>
                    <Link
                      to="/physiotherapie/krankengymnastik"
                      activeClassName="active"
                      onClick={() => {
                        closeMenu();
                      }}
                    >
                      Krankengymnastik
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/physiotherapie/manuelle-therapie"
                      activeClassName="active"
                      onClick={() => {
                        closeMenu();
                      }}
                    >
                      Manuelle Therapie
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/physiotherapie/behandlungen-nach-bobath"
                      activeClassName="active"
                      onClick={() => {
                        closeMenu();
                      }}
                    >
                      Behandlung nach Bobath / PNF
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/physiotherapie/manuelle-lymphdrainage"
                      activeClassName="active"
                      onClick={() => {
                        closeMenu();
                      }}
                    >
                      Manuelle Lymphdrainage
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/physiotherapie/krankengymnastik-am-geraet"
                      activeClassName="active"
                      onClick={() => {
                        closeMenu();
                      }}
                    >
                      Krankengymnastik am Ger&auml;t
                    </Link>
                  </li>
                </SubMenu>
              </li>
              <li>
                <LinkStyles
                  aria-haspopup="true"
                  to="/praxis"
                  activeClassName="active"
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  Praxis
                </LinkStyles>
                <SubMenu className="sub-menu">
                  <li>
                    <Link
                      to="/praxis/stellenangebote"
                      activeClassName="active"
                      onClick={() => {
                        closeMenu();
                      }}
                    >
                      Stellenangebote
                    </Link>
                  </li>
                </SubMenu>
              </li>
              <li className="desktop-hidden">
                <LinkStyles
                  to="/praxis/stellenangebote"
                  activeClassName="active"
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  Stellenangebote
                </LinkStyles>
              </li>
              <li>
                <LinkStyles
                  to="/kontakt"
                  activeClassName="active"
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  Kontakt
                </LinkStyles>
              </li>
            </MainNav>
          </NavBar>
        </NavRow>
      </NavStyles>
    </NavWrapper>
  );
}

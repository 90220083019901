import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { lighten, darken } from 'polished';
import mq from "../../styles/media-queries";
import { Container, Row } from '../../styles/common/Layout';

const FooterStyles = styled.footer`
  position: relative;
  z-index: 20;
  padding: 30px 0px 30px;
  color: var(--white);
  background-color: var(--mainColor);
`

const FooterRow = styled(Row)`
  justify-content: center;
`;

const FooterContent = styled.div`
  display: block;
  text-align: center;

  p {
    font-size: 1.3rem;
    margin: 0;
    text-align:center;


    a {
      font-size: 1.4rem;
      transition: color .25s;
      color: var(--white);
      &:hover {
        color: ${darken(0.3, '#982384')};
      }
    }
  }
`

const Footer = () => (
  <FooterStyles>
    <Container>
      <FooterRow>
        <FooterContent>
          <p>
            &copy; {new Date().getFullYear()} Copyright Physiotherapie Stefan Dumpe <br /> <Link to="/impressum">Impressum</Link> | <Link to="/datenschutz">Datenschutz</Link>
          </p>
        </FooterContent>
      </FooterRow>
    </Container>
  </FooterStyles>
);

export default Footer;

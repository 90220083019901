import React from 'react';
import 'normalize.css';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

import InfoFooter from '../components/Footer/InfoFooter';


export default ({ children, pageContext, location }) => {
  console.log(location)
  var location2 = location.pathname;

  if (pageContext.layout === "special") {
    return (
      <>
        <GlobalStyles />
        <Typography />
        <Header />
        {children}
        <Footer />
      </>

    )
  }

  return (
    <>
      <GlobalStyles />
      <Typography />
      <Header location={location2} />
      {children}
      <InfoFooter />
      <Footer />
    </>
  );
}

import { createGlobalStyle } from 'styled-components';
import fontRegular from '../assets/fonts/Lato-Regular.ttf';
import fontBold from '../assets/fonts/Lato-Bold.ttf';
import fontThin from '../assets/fonts/Lato-Thin.ttf';
import VarelaRegular from '../assets/fonts/varela-round/VarelaRound-Regular.otf';
import MuliRegular from '../assets/fonts/muli/Muli-Regular.ttf';
import mq from "./media-queries"

const Typography = createGlobalStyle`
   @font-face {
    font-family: 'Lato-Thin';
    src: url(${fontThin});
    font-weight: 300;
    font-display: swap;
  }
  @font-face {
    font-family: 'Lato-Regular';
    src: url(${fontRegular});
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: 'Lato-Bold';
    src: url(${fontBold});
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: 'Muli-Regular';
    src: url(${MuliRegular});
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: 'Varela-Regular';
    src: url(${VarelaRegular});
    font-weight: 400;
    font-display: swap;
  }

  html {
    font-family: 'Lato-Regular' ,'Courier New', Courier, monospace, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
  }
  p, address {
    letter-spacing: 0.5px;
    font-size: 1.5rem;
    text-align: left;
    font-style:normal;
    margin: 0;
    margin-bottom: 1em;
    word-break: break-word;
    hyphens: auto;
  }

  a {
    font-size: 1.5rem;
    color: var(--darkgrey);
    text-decoration:none;

    &:hover {
      color: var(--mainColor);
    }
  }

  dl,dd {
    font-size: 1.5rem;
  }

  ul, li {
    font-size: 1.5rem;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family:'Lato-Regular';
    text-align:left;
    font-weight: normal;
    margin: 0;
  }

  h1 {
    font-size: 2rem;

    ${mq.md} {
      font-size: 3rem;
    }
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.6rem;
  }
  strong {
    span {
      color: var(--mainColor);
    }
  }

  .center {
    text-align: center;
  }

`;

export default Typography;

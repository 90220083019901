import React, { useState, useRef } from "react"
import { useScrollData } from "scroll-data-hook"
import styled from "styled-components"
import mq from "../../styles/media-queries"
import Nav from "./Nav"
import TopBar from "./TopBar"
import LowerBar from "./LowerBar"

const HeaderPlaceholderStyles = styled.div`
  display: none;

  ${mq.sm} {
    height: 120px;
    width: 100%;
    display: none;
  }
`

const HeaderStyles = styled.header`
  z-index: 201;
  overflow: hidden;

  ${mq.sm} {
    overflow: visible;
  }
`

export default function Header({ location, data }) {
  const { position } = useScrollData()
  console.log(location)
  return (
    <>
      <HeaderStyles className={position.y > 45 ? "is-fixed" : ""}>
        <TopBar />
        <Nav position={position} />
      </HeaderStyles>
      <HeaderPlaceholderStyles
        style={{ display: position.y > 45 ? "block" : "none" }}
      />
    </>
  )
}

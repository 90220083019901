import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { lighten } from 'polished';
import mq from '../../styles/media-queries';
import { Container, Row } from '../../styles/common/Layout';
import { Link } from 'gatsby';
const TopHeaderStyles = styled.div`
  display: none;
  ${mq.md} {
    display: flex;
  }
  padding: 0;
  background-color: var(--mainColor);
`;

const TopBarRow = styled(Row)`
  background-color: var(--mainColor);
  display: flex;
  position: relative;
  padding: 0;
  font-family: 'Muli-Regular';
`;
const TopBarItemWrapper = styled.div`
  flex-grow: 1;

  > div {
    width: 360px;
  }
`;
const TopBarItem = styled.div`
  height: 45px;
  line-height: 45px;
  padding: 0 1rem;
  cursor: pointer;

  &:hover {
    background: ${lighten(0.55, '#982384')};
    a,
    p,
    span,
    .icon {
      color: var(--mainColor);
    }
  }

  p,
  a {
    font-size: 1.5rem;
    margin: 0;
    color: var(--white);
  }
`;

const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  .icon {
    display: flex;
    color: white;
  }

  span {
    color: var(--white);
    font-size: 1.5rem;
    font-weight: 400;
    &.text-value {
      margin-left: 0.6rem;
    }
  }
`;

export default function TopBar() {
  return (
    <TopHeaderStyles>
      <Container>
        <TopBarRow>
          <TopBarItemWrapper>
            <TopBarItem>

            </TopBarItem>
          </TopBarItemWrapper>
          <TopBarItem lightPink>
            <IconTextWrapper>
              <div className="icon">
                <FontAwesomeIcon icon={faPhoneAlt} size="lg" />
              </div>
              <span className="text-value">
                {' '}
                <a href="tel:+49028652026291" data-rel="external">
                  02865-2026291
                </a>{' '}
              </span>
            </IconTextWrapper>
          </TopBarItem>
          <TopBarItem className="last-item" lightPink>
            <IconTextWrapper>
              <div className="icon">
                <FontAwesomeIcon icon={faEnvelope} size="lg" />
              </div>{' '}
              <span className="text-value">
                <a href="mailto:info@physiotherapie-dumpe.de">
                  info@physiotherapie-dumpe.de
                </a>
              </span>
            </IconTextWrapper>
          </TopBarItem>
        </TopBarRow>
      </Container>
    </TopHeaderStyles>
  );
}

import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { lighten } from "polished"
import { Link } from "gatsby"
import mq from "../../styles/media-queries"
import { Container, Row } from "../../styles/common/Layout"

const TopHeaderStyles = styled.div`
  display: none;
  ${mq.md} {
    display: flex;
  }
  padding: 3rem 0;
  background-color: var(--lightgrey);
`

const TopBarRow = styled(Row)`
  background-color: var(--lightgrey);
  display: flex;
  position: relative;
  padding: 0;
  font-family: "Muli-Regular";
`
const TopBarItemWrapper = styled.div`
  flex-grow: 1;

  > div {
    width: 360px;
  }
`

const TopBarItem = styled.div`
  flex-grow: 1;
  line-height: 3rem;
  padding: 0 1rem;
  text-align: center;

  p,
  a {
    font-size: 1.5rem;
    margin: 0;
    color: var(--darkgrey);
    text-align: center;
  }
`

const H3 = styled.h3`
  color: var(--white);
  background: var(--mainColor);
  font-size: 1.4rem;

  color: rgb(255, 255, 255);
  display: inline-block;
  font-family: Lato-Regular, sans-serif;
  font-weight: bold;
  line-height: 1.4rem;
  letter-spacing: 1px;
  margin: 0px 0px 15px;
  padding: 10px 15px 8px;
  text-transform: uppercase;

  ${mq.md} {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
`

export default function TopBar() {
  return (
    <TopHeaderStyles>
      <Container>
        <TopBarRow>
          <TopBarItem>
            <H3>
              <strong>Wir sind im Urlaub! </strong>
            </H3>
            <p>
              Von Freitag, 27.08.2021 bis Sonntag, 05.09.2021 machen wir eine
              wohlverdiente Pause.
            </p>
            <p>
              Für weitere Fragen stehen wir Ihnen jederzeit gerne zur Verfügung:{" "}
              <a href="mailto:info@physiotherapie-dumpe.de">
                info@physiotherapie-dumpe.de
              </a>
            </p>
          </TopBarItem>
        </TopBarRow>
      </Container>
    </TopHeaderStyles>
  )
}

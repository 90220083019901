module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Physiotherapie Dumpe","short_name":"PhysiotherapieDumpe","start_url":"/","background_color":"#982384","theme_color":"#a2466c","display":"standalone","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4ae185d3f928a33dc29a91680591d714"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Startseite","exclude":["/dev-404-page/","/404/","/404.html"],"crumbLabelUpdates":[{"pathname":"/physiotherapie","crumbLabel":"Physiotherapie"},{"pathname":"/praxis","crumbLabel":"Praxis"}],"trailingSlashes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

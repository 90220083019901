const breakpoints = {
  xs: '31em', // 510px
  sm: '48em', // 768px
  md: '64em', // 1024px
  lg: '85.375em', // 1366px
  xl: '120em',// 1920px
  xxl: '160em'// 2560px
}

const mq = Object.entries(breakpoints).reduce((accumulator, [label, bp]) => {
  accumulator[label] = `@media (min-width: ${bp})`
  return accumulator
}, {})

export default mq
// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-corona-js": () => import("./../../../src/pages/corona.js" /* webpackChunkName: "component---src-pages-corona-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-physiotherapie-behandlungen-nach-bobath-js": () => import("./../../../src/pages/physiotherapie/behandlungen-nach-bobath.js" /* webpackChunkName: "component---src-pages-physiotherapie-behandlungen-nach-bobath-js" */),
  "component---src-pages-physiotherapie-js": () => import("./../../../src/pages/physiotherapie.js" /* webpackChunkName: "component---src-pages-physiotherapie-js" */),
  "component---src-pages-physiotherapie-krankengymnastik-am-geraet-js": () => import("./../../../src/pages/physiotherapie/krankengymnastik-am-geraet.js" /* webpackChunkName: "component---src-pages-physiotherapie-krankengymnastik-am-geraet-js" */),
  "component---src-pages-physiotherapie-krankengymnastik-js": () => import("./../../../src/pages/physiotherapie/krankengymnastik.js" /* webpackChunkName: "component---src-pages-physiotherapie-krankengymnastik-js" */),
  "component---src-pages-physiotherapie-manuelle-lymphdrainage-js": () => import("./../../../src/pages/physiotherapie/manuelle-lymphdrainage.js" /* webpackChunkName: "component---src-pages-physiotherapie-manuelle-lymphdrainage-js" */),
  "component---src-pages-physiotherapie-manuelle-therapie-js": () => import("./../../../src/pages/physiotherapie/manuelle-therapie.js" /* webpackChunkName: "component---src-pages-physiotherapie-manuelle-therapie-js" */),
  "component---src-pages-praxis-js": () => import("./../../../src/pages/praxis.js" /* webpackChunkName: "component---src-pages-praxis-js" */),
  "component---src-pages-praxis-stellenangebote-js": () => import("./../../../src/pages/praxis/stellenangebote.js" /* webpackChunkName: "component---src-pages-praxis-stellenangebote-js" */)
}


import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #FF4949;
    --black: #2E2E2E;
    --yellow: #ffc600;
    --white: #fff;
    --grey: #bdbcbc;
    --lightgrey: #dadada;
    --darkgrey: #555555;
    --mainColor: #982384;
    /** Page: Physiotherapie */
    --gap_desktop: 12px;
    --gap_mobile: 0;
  }
  * {
    box-sizing: border-box;
  }
  html {
    font-size: 10px;
  }
  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }
  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--mainColor) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--mainColor) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }
  hr {
    border: 0;
    height: 8px;
  }
  img {
    max-width: 100%;
  }

  .btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.42857143;
    color: var(--darkgrey);
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: var(--white);
    background-image: none;
    border: 2px solid var(--lightgrey);
    border-radius: 4px;
    transition: all 0.25s ease 0s;

    &:hover,
    &:focus {
      color:var(--white);
      border: 2px solid var(--mainColor);
      box-shadow: inset 0 -3.25em 0 0 var(--mainColor);
    }
  }
  .btn-lg {
    padding: 10px 16px;
    font-size: 1.6rem;
    line-height: 1.3333333;
    text-transform: uppercase;
    border-radius: 6px;
  }

  .f-direction-col {
    flex-direction: column;
  }
`;

export default GlobalStyles;

import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRoute,
  faPhoneAlt,
  faEnvelope,
  faLink,
  faClock,
  faCalendarCheck,
  faParking,
  faTrain,
} from '@fortawesome/free-solid-svg-icons';
import mq from '../../styles/media-queries';
import { Container, Row } from '../../styles/common/Layout';

const FooterStyles = styled.footer`
  position: relative;
  z-index: 20;
  padding: 25px 0px 25px;
  color: var(--white);
  background-color: var(--lightgrey);
`;

const FooterRow = styled(Row)``;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${mq.md} {
    flex-direction: row;
  }

  p {
    font-size: 1.6rem;
    text-align: left;
    color: var(--darkgrey);
    margin: 0;

    a {
      font-size: 1.6rem;
      transition: color 0.25s;
      color: var(--darkgrey);
      &:hover {
        color: var(--mainColor);
      }
    }
  }
`;

const FooterItem = styled.div`
  flex: 0 1 ${(props) => (props.size ? props.size : '33.333%')};
  padding: 0;
  ${mq.md} {
    padding-left: 15px;
    padding-right: 15px;
  }
  h1 {
    color: var(--darkgrey);
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    ${mq.md} {
      text-align: left;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 15px;

    li {
      display: flex;
      margin-bottom: 1rem;
      &:last-child {
        margin: 0;
      }
    }
  }
`;

const IconWrapper = styled.div`
  margin-right: 1.5rem;
  color: var(--darkgrey);
`;

const DL = styled.dl`
  margin: 0;
  dt {
    float: left;
    clear: left;
    width: 90px;
    text-align: left;
    font-weight: bold;
    color: var(--darkgrey);
  }

  dd {
    margin: 0 0 0 95px;
    padding: 0 0 0.5em 0;
    color: var(--darkgrey);
  }
`;

const InfoFooter = () => (
  <FooterStyles>
    <Container>
      <FooterRow>
        <FooterContent>
          <FooterItem>
            <h1>Kontakt Details</h1>
            <ul>
              <li>
                <IconWrapper>
                  <FontAwesomeIcon icon={faRoute} size="lg" />
                </IconWrapper>
                <p>
                  Physiotherapie Stefan Dumpe
                  <br />
                  Silvesterstr. 6<br />
                  46348 Raesfeld
                  <br />
                  Deutschland
                  <br />
                </p>
              </li>
              <li>
                <IconWrapper>
                  <FontAwesomeIcon icon={faPhoneAlt} size="lg" />
                </IconWrapper>
                <a href="tel:+49-02865-2026291">02865-2026291</a>
              </li>
              <li>
                <IconWrapper>
                  <FontAwesomeIcon icon={faEnvelope} size="lg" />
                </IconWrapper>
                <p>
                  <a href="mailto:info@physiotherapie-dumpe.de">
                    info@physiotherapie-dumpe.de
                  </a>
                </p>
              </li>
              <li>
                <IconWrapper>
                  <FontAwesomeIcon icon={faLink} size="lg" />
                </IconWrapper>
                <p>
                  <a href="https://www.physiotherapie-dumpe.de">
                    www.physiotherapie-dumpe.de
                  </a>
                </p>
              </li>
            </ul>
          </FooterItem>
          <FooterItem size="50%">
            <h1>&Ouml;ffnungszeiten</h1>
            <ul>
              <li>
                <IconWrapper>
                  <FontAwesomeIcon icon={faClock} size="lg" />
                </IconWrapper>
                <DL>
                  <dt>Montag</dt>
                  <dd>8:00 - 18:30 Uhr</dd>
                  <dt>Dienstag</dt>
                  <dd>8:00 - 12:00 Uhr danach Hausbesuchszeit</dd>
                  <dt>Mittwoch</dt>
                  <dd>8:00 - 18:00 Uhr</dd>
                  <dt>Donnerstag</dt>
                  <dd>8:00 - 18:30 Uhr</dd>
                  <dt>Freitag</dt>
                  <dd>8:00 - 12:00 Uhr danach Hausbesuchzeit</dd>
                  <dt />
                  <dd>Samstag &amp; Sonntag geschlossen.</dd>
                </DL>
              </li>
              <li>
                <IconWrapper>
                  <FontAwesomeIcon icon={faCalendarCheck} size="lg" />
                </IconWrapper>
                <p>Individuelle Termine nach Absprache</p>
              </li>
            </ul>
          </FooterItem>
          <FooterItem>
            <h1>Anfahrt &amp; Parken</h1>
            <ul>
              <li>
                <IconWrapper>
                  <FontAwesomeIcon icon={faClock} size="lg" />
                </IconWrapper>
                <p>
                  Kostenloser Parkplatz / Stellplätze
                  <br /> direkt vor dem Haus
                </p>
              </li>
              <li>
                <IconWrapper>
                  <FontAwesomeIcon icon={faCalendarCheck} size="lg" />
                </IconWrapper>
                <p>
                  Raesfeld Erle Silvesterstr.:
                  <br />
                  Buslinie 71 / 293
                </p>
              </li>
            </ul>
          </FooterItem>
        </FooterContent>
      </FooterRow>
    </Container>
  </FooterStyles>
);

export default InfoFooter;

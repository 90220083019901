import React from 'react';
import { useStaticQuery, StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components';

const StyledImg = styled(GatsbyImage)`
  display: block;
`;

const Wrapper = styled.figure`
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0;
  transition: transform 0.4s;
`;

const WrappedImg = (props) => (
  <Wrapper>
    <StyledImg {...props} />
  </Wrapper>
);

export default function Logo() {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      logo: sanityStoreSettings {
        name
        alternativeImage {
          asset {
            gatsbyImageData(width: 220)
          }
        }
      }
    }
  `);

  return (
    <>
      <WrappedImg
        image={data.logo.alternativeImage.asset.gatsbyImageData}
        alt={data.logo.name}
      />
    </>
  );
}
